import { translateFunc } from "client/utils/translateFunc";
import type { ChangeEvent, KeyboardEvent } from "react";
import { Loader } from "../Icons/Loader/Loader";
import { UrlIcon } from "../Icons/UrlIcon";

export interface UrlInputProps {
    url: string;
    handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
    handleKeyPress: (e: KeyboardEvent<HTMLInputElement>) => void;
    handleSubmit: () => void;
    isValidating: boolean;
}

export const UrlInput = ({ url, handleChange, handleKeyPress, handleSubmit, isValidating }: UrlInputProps) => {
    return (
        <div className="flex">
            <div className="w-full text-xl flex items-center bg-irBlue-600 dark:bg-darkSurface-200 pl-4 rounded-l-md">
                <div>
                    <UrlIcon  className="fill-white" width={25} height={25} />
                </div>
                <input
                    value={url}
                    onChange={handleChange}
                    onKeyPress={handleKeyPress}
                    className="bg-transparent w-full h-full min-w-76 border-none outline-none ml-2 placeholder:text-gray-300"
                    placeholder="https://"
                    spellCheck="false"
                    autoFocus={true}
                    data-testid="url-upload-input"
                />
            </div>
            <div
                onClick={handleSubmit}
                className="rounded-r-md text-blue-400 font-semibold bg-white dark:bg-darkSurface-100 dark:text-white p-4 capitalize cursor-pointer"
                data-testid="url-upload-button"
            >
                {isValidating ? <Loader height={12} width={12} /> : translateFunc("go")}
            </div>
        </div>
    );
};
