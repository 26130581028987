export const loadScript = (
    url: string,
    callBack: () => void,
    attributes: { key: string; value: string; }[] = [],
    globalObject?: string,
) => {
    // @ts-ignore
    if (typeof globalObject !== "undefined" && window[globalObject]) {
        callBack();
        return;
    }
    if (isScriptLoaded(url)) {
        callBack();
        return;
    }

    const script = document.createElement("script");
    script.setAttribute("src", url);
    script.async = true;

    attributes.forEach(({ key, value }) => {
        script.setAttribute(key, value);
    });

    script.addEventListener("load", callBack, false);

    document.head.appendChild(script);
};

const isScriptLoaded = (src: string) => [...document.getElementsByTagName("script")].find(script => script.getAttribute("src") === src);


