import { FC, HTMLAttributes } from "react";

export interface ISVG {
    width?: number;
    height?: number;
    fill?: string;
    className?: HTMLAttributes<SVGElement>["className"];
}

export const UrlIcon: FC<ISVG> = ({ width = 30, height = 30, className = "fill-black dark:fill-white" }) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 18 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path d="M9.61219 11.0611L7.1635 13.5099C7.1635 13.5099 7.1635 13.51 7.16344 13.51C7.16344 13.51 7.16344 13.51 7.16338 13.51C6.15078 14.5227 4.50304 14.5227 3.49032 13.51C2.99971 13.0194 2.72959 12.3671 2.72959 11.6734C2.72959 10.9798 2.99971 10.3276 3.49014 9.837C3.4902 9.83694 3.49026 9.83688 3.49032 9.83682L5.93901 7.38807C6.27707 7.04996 6.27706 6.50175 5.93895 6.1637C5.6009 5.82564 5.05269 5.82564 4.71458 6.1637L2.26588 8.61245C2.26571 8.61262 2.26554 8.61285 2.26536 8.61303C1.44813 9.43055 0.998047 10.5174 0.998047 11.6734C0.998047 12.8297 1.44831 13.9167 2.26594 14.7344C3.1099 15.5783 4.21837 16.0003 5.32691 16.0003C6.43544 16.0003 7.54398 15.5783 8.38782 14.7344V14.7343C8.38788 14.7343 8.38788 14.7343 8.38788 14.7343L10.8366 12.2855C11.1746 11.9475 11.1746 11.3993 10.8365 11.0611C10.4985 10.7231 9.95036 10.7231 9.61219 11.0611Z" />
            <path d="M17.0024 4.32679C17.0024 3.17052 16.5521 2.08346 15.7345 1.26582C14.0466 -0.42197 11.3003 -0.421913 9.61261 1.26582C9.61255 1.26594 9.61243 1.266 9.61238 1.26611L7.16374 3.71469C6.82563 4.05275 6.82563 4.60101 7.16374 4.93907C7.33286 5.10818 7.55438 5.19268 7.77596 5.19268C7.99748 5.19268 8.21912 5.10812 8.38812 4.93907L10.8368 2.49049C10.8368 2.49037 10.8369 2.49032 10.837 2.4902C11.8496 1.47759 13.4974 1.47753 14.5101 2.4902C15.0007 2.9808 15.2709 3.63308 15.2709 4.32679C15.2709 5.02045 15.0008 5.67261 14.5103 6.16321C14.5102 6.16327 14.5102 6.16333 14.5101 6.16338L12.0614 8.61214C11.7234 8.95019 11.7234 9.4984 12.0615 9.83651C12.2305 10.0056 12.4521 10.0901 12.6736 10.0901C12.8952 10.0901 13.1168 10.0056 13.2858 9.83651L15.7345 7.38776C15.7347 7.38759 15.7349 7.38736 15.7351 7.38718C16.5523 6.56966 17.0024 5.48277 17.0024 4.32679Z" />
            <path d="M5.93913 11.061C6.10819 11.23 6.32977 11.3146 6.55129 11.3146C6.77287 11.3146 6.99445 11.23 7.16351 11.061L12.061 6.16346C12.3991 5.82541 12.3991 5.2772 12.061 4.93909C11.7229 4.60103 11.1747 4.60103 10.8366 4.93909L5.93913 9.83653C5.60102 10.1747 5.60102 10.7229 5.93913 11.061Z" />
        </svg>
    );
};
