export interface IResizeSettings {
    byDimension: {
        width: number;
        height: number;
        lastModified: "width" | "height";
    };
    byPercentage: {
        percentage: number;
    };
    isLocked: boolean;
    dimensionType: Dimension;
    unit: Unit;
    dpi: DPI;
    expertSettings: IExpertSettings;
    fillColor: {
        shouldFillColor: boolean;
        color: string;
    };
}

export type Unit = typeof units[number];
export type DPI = typeof DPIS[number];
export type ImageExtension = typeof imageExentsions[number];
export type Dimension = typeof dimensions[number];

export interface IExpertSettings {
    targetFileSize: number;
    saveAs: ImageExtension;
}

export const dimensions = ["pixels", "percentage"] as const;
export const units = ["pixels", "percent", "inch", "cm", "mm"] as const;
export const DPIS = ["300", "400", "500", "600"] as const;
export const imageExentsions = [
    ".jpg",
    ".jpeg",
    ".jpe",
    ".jif",
    ".jfif",
    ".jfi",
    ".png",
    ".gif",
    ".webp",
    ".tiff",
    ".tif",
    ".psd",
    ".raw",
    ".arw",
    ".cr2",
    ".nrw",
    ".k25",
    ".bmp",
    ".dib",
    ".heif",
    ".heic",
    ".ind",
    ".indd",
    ".indt",
    ".jp2",
    ".j2k",
    ".jpf",
    ".jpx",
    ".jpm",
    ".mj2",
    ".svg",
    ".svgz",
    ".ai",
    ".eps",
] as const;

// export const allSupportedExtensions = ["jpg", "jpeg", "png", "webp", "heic", "heif"];
export const allSupportedExtensions = ["jpg", "jpeg", "png", "webp"];
