import { imageUploaderSlice } from "./slice";

export const {
    setUploadType,
    setFile,
    setFileState,
    removeFile,
    addImage,
    deleteImage,
    deleteAllImages,
    reset,
    showInvalidImageExtensionModal,
    hideInvalidImageExtensionModal,
} = imageUploaderSlice.actions;
