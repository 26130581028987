import { FC } from "react";
import { ISVG } from "./UrlIcon";

interface IProps extends Omit<ISVG, "fill"> {}

export const GoogleDriveIcon: FC<IProps> = ({ width, height }) => {
    const _width = width ?? 30;
    const _height = height ?? 30;

    return (
        <svg
            width={_width}
            height={_height}
            viewBox="0 0 18 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1.41292 13.7119L2.18359 15.0759C2.34373 15.3632 2.57391 15.5888 2.84416 15.7529C3.61824 14.746 4.15695 13.9734 4.46058 13.435C4.76864 12.8886 5.14729 12.0339 5.59651 10.8709C4.38583 10.7076 3.4684 10.626 2.84422 10.626C2.2451 10.626 1.32767 10.7076 0.0917969 10.8709C0.0917969 11.1889 0.171867 11.5068 0.332008 11.794L1.41292 13.7119Z"
                fill="#0066DA"
            />
            <path
                d="M14.8146 15.7529C15.0849 15.5888 15.3151 15.3632 15.4752 15.076L15.7954 14.5119L17.3268 11.794C17.484 11.513 17.5668 11.1948 17.567 10.8709C16.3239 10.7076 15.4082 10.626 14.8196 10.626C14.1871 10.626 13.2713 10.7076 12.0723 10.8709C12.5162 12.0402 12.8898 12.895 13.1933 13.435C13.4993 13.9798 14.0397 14.7524 14.8146 15.7529Z"
                fill="#EA4335"
            />
            <path
                d="M8.83049 5.12813C9.72607 4.01976 10.3433 3.16504 10.6821 2.5641C10.9549 2.08018 11.2552 1.30751 11.5828 0.246155C11.3126 0.0820517 11.0024 0 10.6821 0H6.9789C6.65862 0 6.34844 0.0923344 6.07812 0.246155C6.49493 1.46343 6.84866 2.32976 7.13918 2.84509C7.46028 3.41462 8.02405 4.17561 8.83049 5.12813Z"
                fill="#00832D"
            />
            <path
                d="M12.0626 10.8711H5.59702L2.84473 15.7531C3.1149 15.9172 3.42515 15.9992 3.74543 15.9992H13.9142C14.2344 15.9992 14.5448 15.9069 14.8149 15.753L12.0626 10.8711H12.0626Z"
                fill="#2684FC"
            />
            <path
                d="M8.8293 5.128L6.077 0.246094C5.80669 0.410197 5.57651 0.635787 5.41637 0.923003L0.332008 9.94848C0.174811 10.2295 0.0920046 10.5477 0.0917969 10.8715H5.59651L8.82936 5.128H8.8293Z"
                fill="#00AC47"
            />
            <path
                d="M14.7852 5.43578L12.2429 0.923003C12.0829 0.635787 11.8526 0.410197 11.5824 0.246094L8.83008 5.12807L12.0628 10.8716H17.5575C17.5575 10.5536 17.4775 10.2358 17.3173 9.94855L14.7852 5.43578Z"
                fill="#FFBA00"
            />
        </svg>
    );
};
