import { FileData } from "client/types/FileData";
import { HandleFile } from "client/types/HandleFile";
import { UploadOptions } from "client/types/UploadOptions";
import { formatBytes } from "client/utils/formatBytes";
import { useEffect } from "react";
import { useValidateFiles } from "./useValidateFiles";

export const useDeviceUpload = (handleFile: HandleFile, options: UploadOptions, html_file_input_id: string) => {
    /*
        here an input element is being created with raw js
        because if we create an input element through react
        then on rerender of a component the input element changes
        so the onchange event handler doesn't get fired
        i don't know if there is a better way to do this
        probably using a library might solve this
        but it's working well now
    */
    const setupFileInputEl = () => {
        const prevInput = getFileInputEl();
        if (prevInput) {
            return;
        }

        const inputEl = createFileInputEl();
        inputEl && addEventListener(inputEl);
    };

    const createFileInputEl = () => {
        const prevInput = getFileInputEl();

        if (prevInput) {
            options.allowMultiple
                ? prevInput.setAttribute("multiple", "true")
                : prevInput.setAttribute("multiple", "false");
            return null;
        }

        const input = document.createElement("input");
        input.id = html_file_input_id;
        input.type = "file";
        input.style.display = "none";
        options.allowMultiple ? input.setAttribute("multiple", "true") : input.removeAttribute("multiple");

        input.accept = options.supportedExtensions?.map((ext) => `image/${ext}`).join(",");

        document.body.appendChild(input);

        return input;
    };

    const addEventListener = (input: HTMLInputElement) => input.addEventListener("change", handleFileChange);

    const { validateFiles } = useValidateFiles(options.supportedExtensions);

    const handleFileChange = async (e: Event) => {
        const target = e.target as EventTarget & HTMLInputElement;

        if (!target.files || !target.files[0]) return;

        const fileDatas: FileData[] = [];

        for (let i = 0; i < target.files.length; i++) {
            const file = target && target.files && target.files[i];
            // const src = URL && URL.createObjectURL ?
            //     file.name.toLowerCase().includes("heic") ?
            //         await convertHEICToJPG(file) : URL.createObjectURL(file) : "";
            const src = URL && URL.createObjectURL ? URL.createObjectURL(file) : "";

            fileDatas.push({
                file,
                src,
                size: formatBytes(file.size),
            });
        }

        const files = validateFiles(fileDatas);
        console.log({ files });
        handleFile(files, "device", []);
        target.value = "";
    };

    const getFileInputEl = () => {
        return document.getElementById(html_file_input_id) as HTMLInputElement | null;
    };

    const handleClick = () => {
        getFileInputEl()?.click();
    };

    useEffect(() => {
        setupFileInputEl();
    }, [setupFileInputEl]);

    useEffect(() => {
        const el = getFileInputEl();
        if (!el) return;
        options.allowMultiple ? el.setAttribute("multiple", "true") : el.removeAttribute("multiple");
    }, [options.allowMultiple]);

    return {
        handleClick,
    };
};
