import { FC } from "react";
import { ISVG } from "./UrlIcon";

interface IProps extends Omit<ISVG, "fill"> {}

export const DropBoxIcon: FC<IProps> = ({ width, height }) => {
    const _width = width ?? 30;
    const _height = height ?? 30;

    return (
        <svg
            width={_width}
            height={_height}
            viewBox="0 0 20 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5.09203 0L0.18457 3.10667L5.09203 6.21342L10.0003 3.10659L5.09203 0Z"
                fill="#0061FF"
            />
            <path
                d="M14.9078 0L10 3.10697L14.9078 6.21395L19.8157 3.10697L14.9078 0Z"
                fill="#0061FF"
            />
            <path
                d="M0.18457 9.32054L5.09203 12.4273L10.0003 9.32046L5.09203 6.21387L0.18457 9.32054Z"
                fill="#0061FF"
            />
            <path
                d="M14.9078 6.21387L10 9.32084L14.9078 12.4277L19.8157 9.32084L14.9078 6.21387Z"
                fill="#0061FF"
            />
            <path
                d="M5.09277 13.47L10.001 16.5767L14.9085 13.4699L10.001 10.3633L5.09277 13.47Z"
                fill="#0061FF"
            />
        </svg>
    );
};
