import { ISVG } from "./UrlIcon";

interface IProps extends Omit<ISVG, "fill"> {}

export const DeviceIcon = ({ width, height, className = "fill-black dark:fill-white" }: IProps) => {
    const _width = width ?? 30;
    const _height = height ?? 30;

    return (
        <svg
            width={_width}
            height={_height}
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path d="M20.527 7.35449H15.093C14.4715 7.35449 13.9648 7.8598 13.9648 8.48337V16.8674C13.9648 17.4903 14.4708 17.997 15.093 17.997H20.527C21.1499 17.997 21.6566 17.491 21.6566 16.8674V8.48337C21.6566 7.86049 21.1506 7.35449 20.527 7.35449ZM17.8107 17.5384C17.5756 17.5384 17.3879 17.3487 17.3879 17.1149C17.3879 16.8805 17.5756 16.6921 17.8107 16.6921C18.0445 16.6921 18.2335 16.8805 18.2335 17.1149C18.2335 17.348 18.0445 17.5384 17.8107 17.5384ZM20.8096 15.7399C20.8096 16.0514 20.5573 16.3037 20.2458 16.3037H15.377C15.0655 16.3037 14.8118 16.0514 14.8118 15.7399V9.00037C14.8118 8.68893 15.0655 8.43593 15.377 8.43593H20.2458C20.5573 8.43593 20.8096 8.68893 20.8096 9.00037V15.7399Z" />
            <path d="M2.65892 14.7527V5.89974C2.65892 5.5828 2.91536 5.32568 3.23367 5.32568H15.872C16.1903 5.32568 16.4467 5.5828 16.4467 5.89974V6.50818H17.8107V5.4398C17.8107 4.64643 17.1672 4.00293 16.3752 4.00293H2.7318C1.93842 4.00293 1.29492 4.64643 1.29492 5.4398V15.8994H13.1179V14.7527H2.65892V14.7527Z" />
            <path d="M13.1175 16.4521H0.34375V16.8392C0.34375 17.48 0.862125 17.9983 1.50288 17.9983H13.4764C13.2522 17.6773 13.1182 17.2895 13.1182 16.8688V16.4521H13.1175ZM10.538 17.3926H8.56831C8.41638 17.3926 8.29469 17.2703 8.29469 17.119C8.29469 16.9678 8.41706 16.8461 8.56831 16.8461H10.538C10.6886 16.8461 10.8116 16.9685 10.8116 17.119C10.8123 17.2703 10.6886 17.3926 10.538 17.3926Z" />
        </svg>
    );
};
