import { allSupportedExtensions } from "store/features/resizeDownload/types/resizeTypes";

export const extensionsConfig = {
    "bulk-resize": allSupportedExtensions,
    "color-picker": allSupportedExtensions,
    "image-compressor": ["jpg", "jpeg", "png","gif"],
    "compress-jpeg": ["jpg", "jpeg"],
    "png-compressor": ["png"],
    "gif-compressor": ["gif"],
    flip: allSupportedExtensions,
    rotate: allSupportedExtensions,
    crop: allSupportedExtensions,
    enlarge: allSupportedExtensions,
    resizer: allSupportedExtensions,
    "meme-generator": allSupportedExtensions,
};
