import { AxiosError } from "axios";
import axiosInstance from "client/lib/axiosInterceptor";

export interface IGetReturn<T> {
    result: T | null;
    error: IError | null;
}

interface IError {
    message: string;
    code?: string;
}

export const get = async <T>(url: string): Promise<IGetReturn<T>> => {
    let result: T | null = null;
    let error: {
        message: string;
        code?: string;
    } | null = null;

    try {
        result = (await axiosInstance.get<T>(url)).data;
    } catch (e) {
        const _error = e as AxiosError;
        error = {
            message: _error.message,
            code: _error.code,
        };
    }

    return { result, error };
};
