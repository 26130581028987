import { get } from "client/services/Backend/get";
import { HandleFile } from "client/types/HandleFile";
import { UploadOptions } from "client/types/UploadOptions";
import { isValidUrl } from "client/utils/isValidUrl";
import { translateFunc } from "client/utils/translateFunc";
import { ChangeEvent, KeyboardEvent, useEffect, useMemo, useState } from "react";
import { useValidateFiles } from "./useValidateFiles";

export const useUrlUpload = (
    handleFile: HandleFile,
    onSubmit: () => void,
    options: Omit<UploadOptions, "allowMultiple">,
) => {
    const [url, setUrl] = useState("");
    const [isValidating, setIsValidating] = useState(false);
    const [error, setError] = useState("");
    const INVALID_URL_ERROR = useMemo(() => translateFunc("common:url-error-message"), []);
    const INVALID_MIME_TYPE_ERROR = translateFunc("non-valid-image-mime-type-error");
    const HTML_MEME_TYPE_ERROR = translateFunc("html-mime-type-error");

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setUrl(value);
    };

    const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
        const { key } = event;

        if (key === "Enter") {
            handleSubmit();
        }
    };

    const { validateFiles } = useValidateFiles(options.supportedExtensions);
    const handleSubmit = async () => {
        if (error) return;
        if (!url) {
            setError(INVALID_URL_ERROR);
            return;
        }

        if (!url.includes("freeconvert.com")) {
            setIsValidating(true);
            const contentType = await getContentType(url);
            setIsValidating(false);

            if (contentType && ![...options.supportedExtensions, "octet-stream"].some(ext => contentType.includes(ext))) {
                setError(contentType.toLowerCase().includes("html") ? HTML_MEME_TYPE_ERROR : INVALID_MIME_TYPE_ERROR);
                return;
            }
        }

        onSubmit && onSubmit();
        options.uploadStart && options.uploadStart();

        // TODO:: figure out how to get the correct image extension for url upload
        const file = new File([], url.split("/")[url.split("/").length - 1]);

        handleFile(validateFiles([{ file, src: url }]), "url", { url: url });
        setUrl("");
    };

    const getContentType = async (url: string): Promise<string | undefined> => {
        const { error, result } = await get<{ "content-type": string | undefined; }>(`/api/image-headers?url=${url}`);
        if (error || !result) return undefined;
        return result["content-type"]?.toLowerCase();
    };

    const handleReset = () => setUrl("");

    useEffect(() => {
        if (!url) {
            setError("");
            return;
        }

        if (!isValidUrl(url, options.supportedExtensions)) {
            setError(INVALID_URL_ERROR);
        } else {
            setError("");
        }
    }, [url]);

    return {
        url,
        handleChange,
        handleSubmit,
        error,
        handleKeyPress,
        isValidating,
        handleReset,
    };
};
