import { loadScript } from "client/lib/utils/loadScript";
import { HandleFile } from "client/types/HandleFile";
import { UploadOptions } from "client/types/UploadOptions";
import { formatBytes } from "client/utils/formatBytes";
import { useEffect, useState } from "react";
import { useValidateFiles } from "./useValidateFiles";

export const useDropBoxUpload = (handleFile: HandleFile, uploadOptions: UploadOptions, shouldLoad: boolean) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleClick = () => {
        if (isLoading) return;
        //@ts-ignore
        if (window.Dropbox) {
            //@ts-ignore
            window.Dropbox.choose(options);
        }
    };

    const { validateFiles } = useValidateFiles(uploadOptions.supportedExtensions);
    const options = {
        // Required. Called when a user selects an item in the Chooser.
        success: function (files: any[]) {
            if (!files || !files[0]) {
                alert("Upload Failed");
                return;
            }
            uploadOptions.uploadStart && uploadOptions.uploadStart();

            files.forEach((file) => {
                handleFile(
                    validateFiles([
                        {
                            file: file,
                            size: formatBytes(file.bytes),
                            src: file.link,
                        },
                    ]),
                    "dropBox",
                    {
                        url: file.link,
                        filename: file.name,
                    },
                );
            });
        },

        // Optional. Called when the user closes the dialog without selecting a file
        // and does not include any parameters.
        cancel: function () { },

        // Optional. "preview" (default) is a preview link to the document for sharing,
        // "direct" is an expiring link to download the contents of the file. For more
        // information about link types, see Link types below.
        linkType: "direct", // or "direct"

        // Optional. A value of false (default) limits selection to a single file, while
        // true enables multiple file selection.
        multiselect: uploadOptions.allowMultiple, // or true

        // Optional. This is a list of file extensions. If specified, the user will
        // only be able to select files with these extensions. You may also specify
        // file types, such as "video" or "images" in the list. For more information,
        // see File types below. By default, all extensions are allowed.
        extensions: uploadOptions.supportedExtensions,

        // Optional. A value of false (default) limits selection to files,
        // while true allows the user to select both folders and files.
        // You cannot specify `linkType: "direct"` when using `folderselect: true`.
        folderselect: false, // or true

        // Optional. A limit on the size of each file that may be selected, in bytes.
        // If specified, the user will only be able to select files with size
        // less than or equal to this limit.
        // For the purposes of this option, folders have size zero.
    };

    const handleScriptLoad = () => {
        setIsLoading(true);
        loadScript("https://www.dropbox.com/static/api/2/dropins.js", () => {
            setIsLoading(false);
        }, [{
            key: "id",
            value: "dropboxjs",
        }, {
            key: "type",
            value: "text/javascript"
        }, {
            key: "data-app-key",
            value: "897qqh7949jyme9"
        }]);
    };

    useEffect(() => {
        shouldLoad && handleScriptLoad();
    }, [shouldLoad]);

    return { handleClick, options, isLoading };
};
