import { FileData } from "client/types/FileData";
import { gtag } from "client/utils/adsense/gtag";
import { getImageExtension } from "client/utils/getImageExtension";
import { useDispatch } from "react-redux";
import { showInvalidImageExtensionModal } from "store/features/imageUploader/actions";

export const useValidateFiles = (allowedExtensions: string[]) => {
    const dispatch = useDispatch();

    const validateFiles = (fileDatas: FileData[]): FileData[] => {
        const invalidExts: Set<string> = new Set();
        const validExts: Set<string> = new Set();

        const filteredFileDatas = fileDatas.filter((fileData) => {
            const ext = getImageExtension(fileData.file);

            if (!allowedExtensions.includes(ext)) {
                invalidExts.add(ext);
                return false;
            }

            validExts.add(ext);
            return true;
        });

        if (invalidExts.size > 0) {
            invalidExts.forEach((ext) => {
                gtag("event", "Select Image", {
                    event_category: window.location.pathname === "/" ? "Image Resizer" : window.location.pathname,
                    event_label: `${ext || "null"} not allowed`,
                });
            });

            dispatch(
                showInvalidImageExtensionModal({
                    allowedExts: allowedExtensions,
                    invalidExts: [...invalidExts],
                }),
            );
        }

        return filteredFileDatas;
    };

    return { validateFiles };
};
