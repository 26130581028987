import { twMerge } from "tailwind-merge";
import { ISVG } from "../UrlIcon";

interface LoaderProps extends Omit<ISVG, "fill"> {
    stroke?: string;
}

export const Loader = ({ height, width, className }: LoaderProps) => {
    const _height = height ?? 124;
    const _width = width ?? 124;

    return (
        <svg
            width={_width}
            height={_height}
            viewBox="0 0 124 124"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={twMerge(`animate-spin stroke-irGray-600 dark:stroke-white`, className)}
        >
            <path
                d="M68.3691 106.127C50.2623 108.732 31.6537 99.9449 22.6068 82.8604C11.0939 61.1191 19.3987 34.1192 41.1401 22.6063C47.1813 19.4073 53.6278 17.7384 60.0366 17.4622"
                strokeWidth="22"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M48.2306 104.387C37.5987 100.934 28.2455 93.5087 22.6069 82.8605C11.094 61.1191 19.3988 34.1193 41.1401 22.6064C47.1814 19.4073 53.6279 17.7385 60.0367 17.4623"
                strokeWidth="23"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M30.774 93.8227C27.5811 90.6907 24.8101 87.022 22.6064 82.8604C11.0935 61.1191 19.3983 34.1192 41.1396 22.6063C47.1809 19.4073 53.6274 17.7385 60.0362 17.4622"
                strokeWidth="24"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M19.8691 76.575C12.7829 56.1563 21.453 33.0314 41.1402 22.6064C47.1814 19.4073 53.6279 17.7385 60.0367 17.4622"
                strokeWidth="25"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
